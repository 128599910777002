{
  "venues": {
    "1015 Folsom (San Francisco)": {
      "coordinates": [
        -122.405774,
        37.778204
      ]
    },
    "1015 Folsom, S.F.": {
      "coordinates": [
        -122.405774,
        37.778204
      ]
    },
    "3 Disciples Brewing (Santa Rosa)": {
      "coordinates": [
        -122.7157993,
        38.4392011
      ]
    },
    "7th West (Oakland)": {
      "coordinates": [
        -122.290732,
        37.804128
      ]
    },
    "924 Gilman St., Berkeley": {
      "coordinates": [
        -122.299317,
        37.879581
      ]
    },
    "924 Gilman Street": {
      "coordinates": [
        -122.299327,
        37.879571
      ]
    },
    "924 Gilman Street, Berkeley": {
      "coordinates": [
        -122.299317,
        37.8795805
      ]
    },
    "Above DNA Lounge, S.F.": {
      "coordinates": [
        -122.4126915,
        37.770982000000004
      ]
    },
    "Above the DNA Lounge, S.F.": {
      "coordinates": [
        -122.4126915,
        37.770982000000004
      ]
    },
    "Ace Of Spades (Sacramento)": {
      "coordinates": [
        -121.4909317,
        38.5699587
      ]
    },
    "Almost Famous Wine Lounge, Livermore": {
      "coordinates": [
        -121.769592,
        37.683284
      ]
    },
    "Amber Lounge (Oakland)": {
      "coordinates": [
        -122.2694513,
        37.805209
      ]
    },
    "Amoeba Records, S.F.": {
      "coordinates": [
        -122.4528919,
        37.7691509
      ]
    },
    "Amoeba, S.F.": {
      "coordinates": [
        -122.407632,
        37.765532
      ]
    },
    "Arcana (San Francisco)": {
      "coordinates": [
        -122.41906361547177,
        37.75674104734676
      ]
    },
    "Arena, Oakland": {
      "coordinates": [
        -122.20304,
        37.750338
      ]
    },
    "Ashkenaz, Berkeley": {
      "coordinates": [
        -122.2957155,
        37.8801265
      ]
    },
    "Asiento (San Francisco)": {
      "coordinates": [
        -122.409501,
        37.757703
      ]
    },
    "Au Lounge (Oakland)": {
      "coordinates": [
        -122.264959,
        37.814026
      ]
    },
    "Audio (San Francisco)": {
      "coordinates": [
        -122.413832,
        37.771378
      ]
    },
    "Audio Nightclub (San Francisco)": {
      "coordinates": [
        -122.413832,
        37.771378
      ]
    },
    "Audio, S.F.": {
      "coordinates": [
        -122.413832,
        37.771378
      ]
    },
    "August Hall (San Francisco)": {
      "coordinates": [
        -122.409894,
        37.787443
      ]
    },
    "August Hall, S.F.": {
      "coordinates": [
        -122.409894,
        37.787443
      ]
    },
    "BRIX 581 (Oakland)": {
      "coordinates": [
        -122.27835,
        37.79926
      ]
    },
    "Bal Theater, San Leandro": {
      "coordinates": [
        -122.132881,
        37.708818
      ]
    },
    "Baltic Kiss, Point Richmond": {
      "coordinates": [
        -122.3849101,
        37.9263479
      ]
    },
    "Bandshell, 8204 Music Concourse Drive, Golden Gate Park, S.F.": {
      "coordinates": [
        -122.467235,
        37.770615
      ]
    },
    "Bar Part Time (San Francisco)": {
      "coordinates": [
        -122.42417220176682,
        37.76812113169017
      ]
    },
    "Beaux (San Francisco)": {
      "coordinates": [
        -122.434132,
        37.763493
      ]
    },
    "Bender's, S.F.": {
      "coordinates": [
        -122.417171,
        37.760218
      ]
    },
    "Bergerac (San Francisco)": {
      "coordinates": [
        -122.413712,
        37.771563
      ]
    },
    "Big Easy, 128 American Alley, Petaluma": {
      "coordinates": [
        -122.641287,
        38.234616
      ]
    },
    "Bill Graham Civic Auditorium (San Francisco)": {
      "coordinates": [
        -122.4171575,
        37.778162
      ]
    },
    "Bimbo's 365 Club, S.F.": {
      "coordinates": [
        -122.41565750000001,
        37.803768500000004
      ]
    },
    "Bistro, Hayward": {
      "coordinates": [
        -122.0826,
        37.672883
      ]
    },
    "Blondie's Bar (San Francisco)": {
      "coordinates": [
        -122.421886,
        37.764238
      ]
    },
    "Blue Lagoon, Santa Cruz": {
      "coordinates": [
        -122.025316,
        36.970636
      ]
    },
    "Blue Note, Napa": {
      "coordinates": [
        -122.258949,
        37.863923
      ]
    },
    "Boom Boom Room, S.F": {
      "coordinates": [
        -122.4332374,
        37.7846065
      ]
    },
    "Boom Boom Room, San Francisco": {
      "coordinates": [
        -122.4332374,
        37.7846065
      ]
    },
    "Bottom Of The Hill (San Francisco)": {
      "coordinates": [
        -122.396109,
        37.765108
      ]
    },
    "Bottom of the Hill, S.F.": {
      "coordinates": [
        -122.396109,
        37.765108
      ]
    },
    "Brentwood Emporium, 561 1st Street, Brentwood": {
      "coordinates": [
        -121.693961,
        37.931293
      ]
    },
    "Brick and Mortar, S.F.": {
      "coordinates": [
        -122.420181,
        37.76991
      ]
    },
    "Butter (San Francisco)": {
      "coordinates": [
        -122.413315,
        37.771127
      ]
    },
    "Buzzworks (San Francisco)": {
      "coordinates": [
        -122.412997,
        37.771245
      ]
    },
    "Cafe Du Nord (San Francisco)": {
      "coordinates": [
        -122.43010000000001,
        37.766594999999995
      ]
    },
    "Cafe Du Nord, S.F.": {
      "coordinates": [
        -122.43010000000001,
        37.766594999999995
      ]
    },
    "Cafe du Nord, S.F.": {
      "coordinates": [
        -122.43010000000001,
        37.766594999999995
      ]
    },
    "Caravan Lounge, San Jose": {
      "coordinates": [
        -121.892253,
        37.332907
      ]
    },
    "Caravan, San Jose": {
      "coordinates": [
        -121.8923531,
        37.3329778
      ]
    },
    "Cardiff Lounge (Campbell)": {
      "coordinates": [
        -121.945388,
        37.286885
      ]
    },
    "Cat Club (San Francisco)": {
      "coordinates": [
        -122.409901,
        37.775324
      ]
    },
    "Catalyst Atrium, Santa Cruz": {
      "coordinates": [
        -122.025597,
        36.971359
      ]
    },
    "Catalyst, Santa Cruz": {
      "coordinates": [
        -122.025597,
        36.971359
      ]
    },
    "Chapel, S.F.": {
      "coordinates": [
        -122.42138,
        37.76052
      ]
    },
    "Chase Center (San Francisco)": {
      "coordinates": [
        -122.387426,
        37.7678485
      ]
    },
    "Churchil Arms Pub (Folsom)": {
      "coordinates": [
        -121.162887,
        38.672264
      ]
    },
    "Civic Auditorium, S.F.": {
      "coordinates": [
        -122.4171575,
        37.77816
      ]
    },
    "Club Six (San Francisco)": {
      "coordinates": [
        -122.4096056,
        37.7813345
      ]
    },
    "Club Waziema (San Francisco)": {
      "coordinates": [
        -122.437952,
        37.7746527
      ]
    },
    "Cornerstone, Berkeley": {
      "coordinates": [
        -122.267336,
        37.866325
      ]
    },
    "Crepe Place, Santa Cruz": {
      "coordinates": [
        -122.010887,
        36.979573
      ]
    },
    "Crybaby, Oakland": {
      "coordinates": [
        -122.2694797,
        37.8085784
      ]
    },
    "DNA Lounge (San Francisco)": {
      "coordinates": [
        -122.4126915,
        37.770982000000004
      ]
    },
    "DNA Lounge Above, S.F.": {
      "coordinates": [
        -122.4126915,
        37.770982000000004
      ]
    },
    "DNA Lounge, S.F.": {
      "coordinates": [
        -122.4126915,
        37.770982000000004
      ]
    },
    "Drop 84 (Berkeley)": {
      "coordinates": [
        -122.268118,
        37.8678016
      ]
    },
    "Edinburgh Castle, S.F.": {
      "coordinates": [
        -122.419002,
        37.786163
      ]
    },
    "El Rio (San Francisco)": {
      "coordinates": [
        -122.419465,
        37.746845
      ]
    },
    "Elbo Room Jack London (Oakland)": {
      "coordinates": [
        -122.275896,
        37.796961
      ]
    },
    "Elbo Room, Oakland": {
      "coordinates": [
        -119.770712,
        39.535467
      ]
    },
    "Eli's Mile High Club": {
      "coordinates": [
        -122.269884,
        37.825789
      ]
    },
    "Eli's Mile High Club, Oakland": {
      "coordinates": [
        -122.269884,
        37.825789
      ]
    },
    "Emporium SF (San Francisco)": {
      "coordinates": [
        -122.437701,
        37.775316
      ]
    },
    "Empress Theater, Vallejo": {
      "coordinates": [
        -122.257631,
        38.1022315
      ]
    },
    "Envelop at The Midway (San Francisco)": {
      "coordinates": [
        -122.3856944,
        37.7492774
      ]
    },
    "Ez5 Bar (San Francisco)": {
      "coordinates": [
        -122.404395,
        37.794108
      ]
    },
    "F8 (San Francisco)": {
      "coordinates": [
        -122.409973,
        37.77526
      ]
    },
    "F8 1192 Folsom (San Francisco)": {
      "coordinates": [
        -122.409973,
        37.77526
      ]
    },
    "Faces Nightclub (Sacramento)": {
      "coordinates": [
        -121.4802576,
        38.5752187
      ]
    },
    "Faction Brewing, 2501 Monarch Street, Alameda": {
      "coordinates": [
        -122.3097144,
        37.7867214
      ]
    },
    "Fillmore (San Francisco)": {
      "coordinates": [
        -122.4330825,
        37.784025
      ]
    },
    "Fillmore, S.F.": {
      "coordinates": [
        -122.4330825,
        37.784025
      ]
    },
    "First Church of the Buzzard, 2601 Adeline at 26th St., Oakland": {
      "coordinates": [
        -122.283272,
        37.819053
      ]
    },
    "Flamingo Resort (Santa Rosa)": {
      "coordinates": [
        -122.6884229,
        38.4512947
      ]
    },
    "Folsom Street Foundry (San Francisco)": {
      "coordinates": [
        -122.412972,
        37.772382
      ]
    },
    "Folsom, S.F.": {
      "coordinates": [
        -122.412972,
        37.772382
      ]
    },
    "Fortheculture Oak (Oakland)": {
      "coordinates": [
        -122.2767756,
        37.800767
      ]
    },
    "Fox Theater, Oakland": {
      "coordinates": [
        -122.27067,
        37.808136
      ]
    },
    "Freight": {
      "coordinates": [
        -122.269551,
        37.871025
      ]
    },
    "Freight and Salvage, Berkeley": {
      "coordinates": [
        -122.2695846,
        37.8708514
      ]
    },
    "Fresh Blades Barbershop (San Francisco)": {
      "coordinates": [
        -122.42041,
        37.789474
      ]
    },
    "Frost Amphitheater, Palo Alto": {
      "coordinates": [
        -122.165985,
        37.430602
      ]
    },
    "Fulton 55 (Fresno)": {
      "coordinates": [
        -119.800556,
        36.74374
      ]
    },
    "Golden Bull, Oakland": {
      "coordinates": [
        -122.270409,
        37.803999
      ]
    },
    "Goldfield Trading Post (Roseville)": {
      "coordinates": [
        -121.484119,
        38.577715
      ]
    },
    "Goldfield Trading Post, Sacramento": {
      "coordinates": [
        -121.484119,
        38.577715
      ]
    },
    "Grace Cathedral (San Francisco)": {
      "coordinates": [
        -122.413174,
        37.791782999999995
      ]
    },
    "Gray Area (San Francisco)": {
      "coordinates": [
        -122.41848,
        37.754367
      ]
    },
    "Great American Music Hall (San Francisco)": {
      "coordinates": [
        -122.418953,
        37.78498999999999
      ]
    },
    "Great American Music Hall, S.F.": {
      "coordinates": [
        -122.418953,
        37.78498999999999
      ]
    },
    "Great Northern, S.F": {
      "coordinates": [
        -122.406406,
        37.767478
      ]
    },
    "Greek Theatre (Berkeley)": {
      "coordinates": [
        -122.2542195,
        37.873721
      ]
    },
    "Greek Theatre, UC Berkeley Campus": {
      "coordinates": [
        -122.2542195,
        37.873721
      ]
    },
    "Guild Theater, Menlo Park": {
      "coordinates": [
        -122.181271,
        37.452414
      ]
    },
    "Halcyon (San Francisco)": {
      "coordinates": [
        -122.413905,
        37.771575
      ]
    },
    "Halcyon, 314 11th Street, S.F.": {
      "coordinates": [
        -122.413905,
        37.771575
      ]
    },
    "Harlan Records (San Francisco)": {
      "coordinates": [
        -122.43357,
        37.77169
      ]
    },
    "Harlow's, Sacramento": {
      "coordinates": [
        -121.469989,
        38.573963
      ]
    },
    "Hawthorn (San Francisco)": {
      "coordinates": [
        -122.404167,
        37.787846
      ]
    },
    "Henry Miller Library, Big Sur": {
      "coordinates": [
        -121.753744,
        36.220643
      ]
    },
    "Herbst Theater, S.F.": {
      "coordinates": [
        -122.420953,
        37.779548
      ]
    },
    "Hibernia (San Francisco)": {
      "coordinates": [
        -122.4122892391,
        37.781308592396016
      ]
    },
    "Hopmonk Tavern, Novato": {
      "coordinates": [
        -122.822841,
        38.401164
      ]
    },
    "Hotel Utah (San Francisco)": {
      "coordinates": [
        -122.398139,
        37.779308
      ]
    },
    "Hotel Utah, S.F.": {
      "coordinates": [
        -122.39806,
        37.779381
      ]
    },
    "Hotel Via (San Francisco)": {
      "coordinates": [
        -122.390658,
        37.779213
      ]
    },
    "Hoza Pizzeria (Oakland)": {
      "coordinates": [
        -122.269378,
        37.804986
      ]
    },
    "Independent, S.F.": {
      "coordinates": [
        -122.437835,
        37.775573
      ]
    },
    "Ivy Room, Albany": {
      "coordinates": [
        -122.299128,
        37.890431
      ]
    },
    "Jam Cellars Ballroom, Napa": {
      "coordinates": [
        -122.289293,
        38.297617
      ]
    },
    "Jury Room, 712 Ocean Street, Santa Cruz": {
      "coordinates": [
        -122.020753,
        36.978459
      ]
    },
    "Jury Room, Santa Cruz": {
      "coordinates": [
        -122.020753,
        36.978459
      ]
    },
    "Kilowatt, S.F.": {
      "coordinates": [
        -122.423204,
        37.764971
      ]
    },
    "Kip's (Berkeley)": {
      "coordinates": [
        -122.2595917,
        37.8679934
      ]
    },
    "Knockout, S.F.": {
      "coordinates": [
        -122.420015,
        37.745174
      ]
    },
    "Kuumbwa Jazz Center, Santa Cruz": {
      "coordinates": [
        -122.026127,
        36.969856
      ]
    },
    "Longboard Margarita Bar, Pacifica": {
      "coordinates": [
        -122.488115,
        37.633134
      ]
    },
    "Lost Church, S.F.": {
      "coordinates": [
        -122.418452,
        37.765748
      ]
    },
    "Lowbrau (Sacramento)": {
      "coordinates": [
        -121.48042,
        38.575804
      ]
    },
    "Lucky Duck Bike Cafe, Oakland": {
      "coordinates": [
        -122.268656,
        37.801684
      ]
    },
    "Luther Burbank Center for the Arts, 50 Mark West Springs, Santa Rosa": {
      "coordinates": [
        -122.74963525,
        38.49275325
      ]
    },
    "Luxx Event Space (San Francisco)": {
      "coordinates": [
        -122.409551,
        37.7813734
      ]
    },
    "MOTIV (Santa Cruz)": {
      "coordinates": [
        -122.025856,
        36.973082
      ]
    },
    "Madarae (San Francisco)": {
      "coordinates": [
        -122.39825071778282,
        37.78859528688151
      ]
    },
    "Madrone Art Bar (San Francisco)": {
      "coordinates": [
        -122.43743,
        37.774025
      ]
    },
    "Make-Out Room (San Francisco)": {
      "coordinates": [
        -122.419419,
        37.75537
      ]
    },
    "Make-Out Room, S.F.": {
      "coordinates": [
        -122.419419,
        37.75537
      ]
    },
    "Maonic, S.F.": {
      "coordinates": [
        -122.413022,
        37.791188
      ]
    },
    "Mars Bar (San Francisco)": {
      "coordinates": [
        -122.4032609,
        37.7732839
      ]
    },
    "Masonic (San Francisco)": {
      "coordinates": [
        -122.413022,
        37.791188
      ]
    },
    "Masonic, S.F.": {
      "coordinates": [
        -122.413022,
        37.791188
      ]
    },
    "Mazzotti's (Arcata)": {
      "coordinates": [
        -124.0868234,
        40.8681176
      ]
    },
    "Melo Melo Kava Bar (Oakland)": {
      "coordinates": [
        -122.2470815,
        37.8124911
      ]
    },
    "Midtown Barfly (Sacramento)": {
      "coordinates": [
        -121.479138,
        38.574531
      ]
    },
    "Midway, S.F.": {
      "coordinates": [
        -122.3856944,
        37.7492774
      ]
    },
    "Moe's Alley, Santa Cruz": {
      "coordinates": [
        -121.982871,
        36.987674
      ]
    },
    "Monarch (San Francisco)": {
      "coordinates": [
        -122.408555,
        37.781002
      ]
    },
    "Monarch Gardens (San Francisco)": {
      "coordinates": [
        -122.4120333,
        37.769775
      ]
    },
    "Monarch, S.F.": {
      "coordinates": [
        -122.408555,
        37.781002
      ]
    },
    "Monroe (San Francisco)": {
      "coordinates": [
        -122.405067,
        37.79808
      ]
    },
    "Monroe SF (San Francisco)": {
      "coordinates": [
        -122.405067,
        37.79808
      ]
    },
    "Mothership (San Francisco)": {
      "coordinates": [
        -122.419351,
        37.7469249
      ]
    },
    "Mothership Bar (San Francisco)": {
      "coordinates": [
        -122.419351,
        37.7469249
      ]
    },
    "Mountain Winery, Saratoga": {
      "coordinates": [
        -122.0647455,
        37.260274499999994
      ]
    },
    "Mystic Theater, Petaluma": {
      "coordinates": [
        -122.64012,
        38.233202
      ]
    },
    "Neck Of The Woods (San Francisco)": {
      "coordinates": [
        -122.463689,
        37.783052
      ]
    },
    "Neck of the Woods, S.F.": {
      "coordinates": [
        -122.463689,
        37.783052
      ]
    },
    "Nectar Social Club (Oakland)": {
      "coordinates": [
        -122.423282,
        37.767868
      ]
    },
    "New Parish, Oakland": {
      "coordinates": [
        -122.272751,
        37.807796
      ]
    },
    "No Name Bar, 757 Bridgeway, Sausalito": {
      "coordinates": [
        -122.480223,
        37.856795
      ]
    },
    "Noisebridge Hackerspace (San Francisco)": {
      "coordinates": [
        -122.4185361,
        37.7624302
      ]
    },
    "Oakland Musium of Calfornia, Oakland": {
      "coordinates": [
        -122.2638015,
        37.798667
      ]
    },
    "Oakland Secret Gallery, Oakland": {
      "coordinates": [
        -122.275344,
        37.800213
      ]
    },
    "Oakland Secret, Oakland": {
      "coordinates": [
        -122.244192,
        37.816089
      ]
    },
    "Oasis (San Francisco)": {
      "coordinates": [
        -122.4144155,
        37.7718948
      ]
    },
    "Old Ironsides (Sacramento)": {
      "coordinates": [
        -121.497634,
        38.570281
      ]
    },
    "On The Y (Sacramento)": {
      "coordinates": [
        -121.401738,
        38.577064
      ]
    },
    "Origin (San Francisco)": {
      "coordinates": [
        -122.432804,
        37.783863
      ]
    },
    "Palace of Fine Arts, S.F.": {
      "coordinates": [
        -122.44871,
        37.802068
      ]
    },
    "Paramount Theater, Oakland": {
      "coordinates": [
        -122.268377,
        37.80994
      ]
    },
    "Parliament Event Venue (Oakland)": {
      "coordinates": [
        -122.275047,
        37.8010164
      ]
    },
    "Peacock Lounge (San Francisco)": {
      "coordinates": [
        -122.431239,
        37.772039
      ]
    },
    "Peacock Lounge, 552 Haight Street, S.F.": {
      "coordinates": [
        -122.431239,
        37.772039
      ]
    },
    "Peacock Lounge, S.F.": {
      "coordinates": [
        -122.431239,
        37.772039
      ]
    },
    "Peri's Tavern Patio, Fairfax": {
      "coordinates": [
        -122.588562,
        37.9868736
      ]
    },
    "Persona (San Francisco)": {
      "coordinates": [
        -122.4115129,
        37.7886674
      ]
    },
    "Phoenix Theater, Petaluma": {
      "coordinates": [
        -122.64300349999999,
        38.234977
      ]
    },
    "Phonobar (San Francisco)": {
      "coordinates": [
        -122.422815,
        37.777882
      ]
    },
    "Plough": {
      "coordinates": [
        -122.460546,
        37.783194
      ]
    },
    "Pop's Bar (San Francisco)": {
      "coordinates": [
        -122.408297,
        37.753024
      ]
    },
    "Powerhouse (San Francisco)": {
      "coordinates": [
        -122.412284,
        37.773311
      ]
    },
    "Public Works (San Francisco)": {
      "coordinates": [
        -122.419391,
        37.768948
      ]
    },
    "Public Works Loft (San Francisco)": {
      "coordinates": [
        -122.419391,
        37.768948
      ]
    },
    "Public Works, S.F.": {
      "coordinates": [
        -122.419391,
        37.768948
      ]
    },
    "Pure Night Club (Sunnyvale)": {
      "coordinates": [
        -122.419391,
        37.768948
      ]
    },
    "Pure Nightclub (Sunnyvale)": {
      "coordinates": [
        -122.0298038,
        37.3768037
      ]
    },
    "Purple House,": {
      "coordinates": [
        -122.401337,
        37.788248
      ]
    },
    "Radio Habana Social Club, S.F.": {
      "coordinates": [
        -122.420913,
        37.755237
      ]
    },
    "Record Bar (San Francisco)": {
      "coordinates": [
        -122.4100788,
        37.7841668
      ]
    },
    "Regency Ballroom (San Francisco)": {
      "coordinates": [
        -122.421384,
        37.787763
      ]
    },
    "Regency Ballroom, S.F.": {
      "coordinates": [
        -122.421384,
        37.787763
      ]
    },
    "Rickshaw Stop (San Francisco)": {
      "coordinates": [
        -122.420431,
        37.776105
      ]
    },
    "Rickshaw Stop, S.F.": {
      "coordinates": [
        -122.420431,
        37.776105
      ]
    },
    "Rio Theater, Santa Cruz": {
      "coordinates": [
        -122.010467,
        36.9799735
      ]
    },
    "Ritz, San Jose": {
      "coordinates": [
        -121.88583,
        37.32997
      ]
    },
    "SF Eagle, S.F.": {
      "coordinates": [
        -122.413305,
        37.769995
      ]
    },
    "SF Jazz Center, S.F.": {
      "coordinates": [
        -122.421631,
        37.7764351
      ]
    },
    "San Jose Civic, San Jose": {
      "coordinates": [
        -121.885985,
        37.337789
      ]
    },
    "Sebastiani Theater, Sonoma": {
      "coordinates": [
        -122.456673,
        38.293099
      ]
    },
    "Shark Tank, San Jose": {
      "coordinates": [
        -121.901202,
        37.332866499999994
      ]
    },
    "Shoreline Amphitheater, Mountain View": {
      "coordinates": [
        -122.080363,
        37.426615999999996
      ]
    },
    "Silverado Resort, Napa": {
      "coordinates": [
        -122.2673178,
        38.3483878
      ]
    },
    "Slate Bar (San Francisco)": {
      "coordinates": [
        -122.418122,
        37.765035
      ]
    },
    "Spats (Berkeley)": {
      "coordinates": [
        -122.26864,
        37.872647
      ]
    },
    "Stay Gold Deli, Oakland": {
      "coordinates": [
        -122.275856,
        37.817699
      ]
    },
    "Stern Grove, S.F.": {
      "coordinates": [
        -122.2595917,
        37.8679934
      ]
    },
    "Stork Club, Oakland": {
      "coordinates": [
        -122.2683657,
        37.8131757
      ]
    },
    "Streetlight Records, San Jose": {
      "coordinates": [
        -121.931276,
        37.307959
      ]
    },
    "Subrosa, Santa Cruz": {
      "coordinates": [
        -122.183019,
        37.453264
      ]
    },
    "Svn West (San Francisco)": {
      "coordinates": [
        -122.419648,
        37.774356
      ]
    },
    "Swedish American Hall, S.F.": {
      "coordinates": [
        -122.430563,
        37.766756
      ]
    },
    "Sweetwater Music Hall, Mill Valley": {
      "coordinates": [
        -122.547954,
        37.907057
      ]
    },
    "Tamarack, 1501 Harrison Street, Oakland": {
      "coordinates": [
        -122.267566,
        37.804392
      ]
    },
    "Tamarack, Oakland": {
      "coordinates": [
        -122.267566,
        37.804392
      ]
    },
    "Temple (San Francisco)": {
      "coordinates": [
        -122.397227,
        37.787898
      ]
    },
    "Temple Nightclub (San Francisco)": {
      "coordinates": [
        -122.397227,
        37.787898
      ]
    },
    "Tequila Mockingbird, 86 2nd Street, S.F.": {
      "coordinates": [
        -122.400041,
        37.788263
      ]
    },
    "The Blue Lagoon, Santa Cruz": {
      "coordinates": [
        -122.025316,
        36.970636
      ]
    },
    "The Catalyst Club (Santa Cruz)": {
      "coordinates": [
        -122.025597,
        36.971359
      ]
    },
    "The Center SF (San Francisco)": {
      "coordinates": [
        -122.4307162,
        37.7744549
      ]
    },
    "The Colonial Theatre (Sacramento)": {
      "coordinates": [
        -121.4515798,
        38.5424065
      ]
    },
    "The Continental Bar (San Jose)": {
      "coordinates": [
        -121.886756,
        37.330589
      ]
    },
    "The Continental Bar Lounge &amp; Patio (San Jose)": {
      "coordinates": [
        -121.886756,
        37.330589
      ]
    },
    "The Drop 84 (Berkeley)": {
      "coordinates": [
        -122.268118,
        37.8678016
      ]
    },
    "The Eagle (San Francisco)": {
      "coordinates": [
        -122.413406,
        37.7699936
      ]
    },
    "The Endup (San Francisco)": {
      "coordinates": [
        -122.403872,
        37.777338
      ]
    },
    "The Felix (San Francisco)": {
      "coordinates": [
        -122.401279,
        37.791856
      ]
    },
    "The Fillmore (San Francisco)": {
      "coordinates": [
        -122.4330825,
        37.784025
      ]
    },
    "The Flamingo House (Sacramento)": {
      "coordinates": [
        -121.4757194,
        38.5745331
      ]
    },
    "The Foundry (San Francisco)": {
      "coordinates": [
        -122.4130137,
        37.7723519
      ]
    },
    "The Gallery (Sacramento)": {
      "coordinates": [
        -122.289105,
        37.814805
      ]
    },
    "The Grand Nightclub (San Francisco)": {
      "coordinates": [
        -122.397692,
        37.779125
      ]
    },
    "The Great Northern (San Francisco)": {
      "coordinates": [
        -122.406406,
        37.767478
      ]
    },
    "The Guild Theatre (Menlo Park)": {
      "coordinates": [
        -122.181271,
        37.452414
      ]
    },
    "The Hotel Utah Saloon (San Francisco)": {
      "coordinates": [
        -122.39806,
        37.779381
      ]
    },
    "The Independent (San Francisco)": {
      "coordinates": [
        -122.437835,
        37.775573
      ]
    },
    "The Jam (Arcata)": {
      "coordinates": [
        -124.086986,
        40.869227
      ]
    },
    "The Knockout (San Francisco)": {
      "coordinates": [
        -122.420015,
        37.745174
      ]
    },
    "The Lab (San Francisco)": {
      "coordinates": [
        -122.418845,
        37.765133
      ]
    },
    "The Midway (San Francisco)": {
      "coordinates": [
        -122.3856944,
        37.7492774
      ]
    },
    "The Midway Patio (San Francisco)": {
      "coordinates": [
        -122.3856944,
        37.7492774
      ]
    },
    "The New Parish (Oakland)": {
      "coordinates": [
        -122.272751,
        37.807796
      ]
    },
    "The Park Ultra Lounge (Sacramento)": {
      "coordinates": [
        -121.487594,
        38.576646
      ]
    },
    "The Record Bar (San Francisco)": {
      "coordinates": [
        -122.4100788,
        37.7841668
      ]
    },
    "The Regency Ballroom (San Francisco)": {
      "coordinates": [
        -122.421384,
        37.787763
      ]
    },
    "The Rink Studios (Sacramento)": {
      "coordinates": [
        -121.483114,
        38.575061
      ]
    },
    "The Ritz (San Jose)": {
      "coordinates": [
        -121.88583,
        37.32997
      ]
    },
    "The Royale (San Francisco)": {
      "coordinates": [
        -122.415215,
        37.787487
      ]
    },
    "The Rumpus Room (San Francisco)": {
      "coordinates": [
        -122.409952,
        37.781955
      ]
    },
    "The Stud (San Francisco)": {
      "coordinates": [
        -122.448689,
        37.76982
      ]
    },
    "The Uc Theatre (Berkeley)": {
      "coordinates": [
        -122.269954,
        37.871631
      ]
    },
    "The Valencia Room (San Francisco)": {
      "coordinates": [
        -122.421559,
        37.762505
      ]
    },
    "The Warfield (San Francisco)": {
      "coordinates": [
        -122.410151,
        37.782675499999996
      ]
    },
    "Thee Parkside, S.F.": {
      "coordinates": [
        -122.399842,
        37.765067
      ]
    },
    "Thee Storke Club, Oakland": {
      "coordinates": [
        -122.2683657,
        37.8131757
      ]
    },
    "Thrillhouse Records, S.F.": {
      "coordinates": [
        -122.422312,
        37.742173
      ]
    },
    "Tiger (Sacramento)": {
      "coordinates": [
        -121.3288,
        38.62563
      ]
    },
    "UC Theater, Berkeley": {
      "coordinates": [
        -122.269958,
        37.871601
      ]
    },
    "Underground (San Francisco)": {
      "coordinates": [
        -122.429336,
        37.772305
      ]
    },
    "Uptown Theater, Napa": {
      "coordinates": [
        -122.287376,
        38.29635
      ]
    },
    "Uptown, Napa": {
      "coordinates": [
        -122.418449,
        37.763451
      ]
    },
    "Vinnies Bar and Grill, 2045 Mount Diablo St, Concord": {
      "coordinates": [
        -122.035169,
        37.977914
      ]
    },
    "Vinnies's, 2045 Mt. Diablo Street, Concord": {
      "coordinates": [
        -122.46989,
        38.50465
      ]
    },
    "Warfield, S.F.": {
      "coordinates": [
        -122.410151,
        37.782675499999996
      ]
    },
    "Warriors Stadium, S.F.": {
      "coordinates": [
        -122.387877,
        37.7680183
      ]
    },
    "White Rabbit (San Francisco)": {
      "coordinates": [
        -122.435835,
        37.798712
      ]
    },
    "Winters Tavern, Pacifica": {
      "coordinates": [
        -122.490455,
        37.636643
      ]
    },
    "Yolo Nightclub (San Francisco)": {
      "coordinates": [
        -122.4133157,
        37.7715426
      ]
    },
    "Yoshi's, Oakland": {
      "coordinates": [
        -122.2788889,
        37.7963889
      ]
    },
    "Zanzi (Oakland)": {
      "coordinates": [
        -122.2663121,
        37.8112817
      ]
    },
    "Zoe Bar (San Francisco)": {
      "coordinates": [
        -122.418564,
        37.751658
      ]
    }
  }
}
